import { persistReducer, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import createRootReducer from './reducers/createRootReducer';
import sagas from './sagas';

const persistConfig = {
	blacklist: ['modals', 'userManager', 'avatarManger', 'locationsManager'],
	key: 'synergyhero',
	storage,
};

const loggerActionColors = { success: 'green', failed: 'red', started: 'blue' };

const sagaMiddleware = createSagaMiddleware();

export default (initialState = {}) => {
	const persistedReducer = persistReducer(persistConfig, createRootReducer());

	const store = configureStore({
		reducer: persistedReducer,
		preloadedState: initialState,
		middleware: () => [
			sagaMiddleware,
			createLogger({
				collapsed: true,
				duration: true,
				colors: {
					title: (action) => loggerActionColors[action.type.split('.')[1]],
				},
			}),
		],
		devTools: process.env.NODE_ENV !== 'production',
	});

	const persistor = persistStore(store);

	sagaMiddleware.run(sagas);

	// persistor.purge();

	return { store, persistor };
};
