import { handleActions } from 'redux-actions';

import {
	getAvatarInventoryAsync,
	getAvatarEquipmentAsync,
	updateAvatarInventoryAsync,
	clearInventoryErrorAsync,
} from '../../actions/inventory';
import { guesthouseChestMoveAsync } from '../../actions/guesthouse';
import { changeAvatarEquipmentAsync } from '../../actions/avatar';

import { defaultError } from '../../../common/errors';

const initialState = {
	avatar: null,
	equipment: [],
	error: null,
};

export default handleActions(
	{
		[getAvatarInventoryAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			avatar: responseData.success ? responseData.result.inventory : null,
		}),
		[getAvatarEquipmentAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			equipment: responseData.success ? responseData.result.items : [],
		}),
		[updateAvatarInventoryAsync.success]: (s, { payload: { response } } = {}) => ({
			...s,
			error: response.success ? null : response.message ? response.message : defaultError,
			avatar: response.success ? response.result.inventory : s.avatar,
		}),
		[clearInventoryErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getAvatarInventoryAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			avatar: null,
		}),
		[getAvatarEquipmentAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			equipment: [],
		}),

		[guesthouseChestMoveAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success ? response.result.inventory : s.avatar,
		}),
		[changeAvatarEquipmentAsync.success]: (s, { payload: { data: response } } = {}) => ({
			...s,
			avatar: response.success ? (response.result.inventory ? response.result.inventory : s.avatar) : s.avatar,
		}),
	},
	initialState
);
