import { useLocation, useNavigate } from 'react-router';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { chunk } from 'lodash';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import CloseIcon from '../../components/Icons/CloseIcon';

import { craftItem } from '../../store/actions/crafts';
import { openModal } from '../../store/actions/modals';

import { socket_url } from '../../common/config';
import { itemStats } from '../../common/items';

export default connect((s) => ({}), {
	openModal,
	craftItem,
})(({ craftItem, openModal }) => {
	const { lastMessage } = useWebSocket(
		socket_url,
		{
			share: true,
			shouldReconnect: (e) => {
				console.log(e);
				return true;
			},
			reconnectAttempts: 1000,
			reconnectInterval: 200,
		},
		true
	);

	const [CraftCount, SetCraftCount] = React.useState(1);
	const { t, i18n } = useTranslation();
	const {
		state: { craft },
	} = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (lastMessage) {
			try {
				let content = JSON.parse(lastMessage.data);

				switch (content.type) {
					case 'crafted-item': {
						openModal({
							name: 'crafted-item',
							payload: {
								type: 'custom',
								withoutPadding: true,
								item: content.response.result.craftedItem.item,
								count: content.response.result.craftedItem.count,
								onClose: () => {
									navigate(-1);
								},
							},
						});

						break;
					}
				}
			} catch (error) {}
		}
	}, [lastMessage]);

	return (
		<div className={classNames({ container: true, craft: true })}>
			<div className={classNames({ 'craft-header': true })}>
				<div
					className={classNames({ 'craft-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'craft-header-title': true })}>{t('craft.header.title')}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'craft-info': true })}>
					<div className={classNames({ 'craft-receipe': true })}>
						<div
							style={{ '--item-count': craft.size }}
							className={classNames({ 'craft-shape': true })}
						>
							{chunk(craft.recipe, craft.size).map((row, ri) => (
								<div
									className={classNames({ 'craft-shape-row': true })}
									key={ri}
								>
									{row.map((item, ii) => (
										<div
											className={classNames({ 'craft-shape-item': true })}
											key={ii}
										>
											{item && (
												<img
													className={classNames({ 'craft-shape-item-image': true })}
													src={item.image}
												/>
											)}
										</div>
									))}
								</div>
							))}
						</div>
						<svg
							width='42'
							height='18'
							viewBox='0 0 42 18'
							fill='none'
						>
							<path
								d='M42 9L27 0.339748L27 17.6603L42 9ZM-1.31134e-07 10.5L28.5 10.5L28.5 7.5L1.31134e-07 7.5L-1.31134e-07 10.5Z'
								fill='white'
							/>
						</svg>
						<img
							className={classNames({ 'craft-result-image': true })}
							src={craft.result.item.image}
						/>
					</div>

					<DefaultButton
						text={t('craft.buttons.count', { count: CraftCount })}
						onClick={() =>
							openModal({
								name: 'type-picker',
								payload: {
									type: CraftCount,
									title: t('craft.modals.count'),
									types: [1, 5, 10, 20, 50].map((type) => ({ name: t(`craft.counts.${type}`), value: type })),
									setType: (type) => SetCraftCount(type),
									closeOnBackgroundClick: true,
									withoutPadding: true,
									closeOnPick: true,
								},
							})
						}
						type={'primary'}
						fontSize={15}
						height={40}
					/>

					<div className={classNames({ 'item-stats': true })}>
						<span className={classNames({ 'item-stats-text': true, strong: true, center: true })}>
							{craft.result.item.name[i18n.languages[0]]}
						</span>
						<span className={classNames({ 'item-stats-text': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true })}>{t(`craft.stats-names.description`)}</span>
							{craft.result.item.description[i18n.languages[0]]}
						</span>
						{itemStats[craft.result.item.type].map((v, i) => (
							<span
								className={classNames({ 'item-stats-text': true })}
								key={i}
							>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>{t(`craft.stats-names.${v}`)}</span>
								{t(
									`craft.stats.${v}`,
									typeof craft.result.item[v.split('-')[0]] == 'object'
										? { ...craft.result.item[v.split('-')[0]] }
										: {
												value:
													v == 'rarity' ? t(`location-inventory-modal.rarities.${craft.result.item[v]}`) : craft.result.item[v],
										  }
								)}
							</span>
						))}
						{craft.result.item.requires.length ? (
							<span className={classNames({ 'item-stats-text': true })}>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`craft.stats-names.requirements`)}
								</span>
								{craft.result.item.requires.map((v) => t(`craft.requirements.${v.name}`, { value: v.value })).join(', ')}
							</span>
						) : null}
						<span className={classNames({ 'item-stats-text': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true })}>{t(`craft.stats-names.repair`)}</span>
							{craft.result.item.repair.length
								? craft.result.item.repair.map((v) => `x${v.count} ${t(`resources.${v.name}`)}`).join(', ')
								: t(`craft.unrepairable`)}
						</span>
					</div>
				</div>
			</div>
			<div className={classNames({ 'action-button': true })}>
				<DefaultButton
					onClick={() => craftItem({ craftId: craft._id, count: CraftCount })}
					text={t('craft.buttons.craft')}
					type={'primary'}
					fontSize={20}
					height={50}
				/>
			</div>
		</div>
	);
});
