import React from 'react';

export default ({ width = 512, height = 512 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				minWidth: `${width}px`,
				minHeight: `${height}px`,
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill='#010101'
					style={{ opacity: 0.959 }}
					d='M 172.5,-0.5 C 177.167,-0.5 181.833,-0.5 186.5,-0.5C 193.004,1.83077 197.838,6.16411 201,12.5C 202.384,19.4112 202.884,26.4112 202.5,33.5C 211.615,32.6003 220.282,34.1003 228.5,38C 237.846,46.5378 240.013,56.7044 235,68.5C 231.9,73.8001 227.4,77.3001 221.5,79C 215.175,79.4995 208.842,79.6662 202.5,79.5C 203.103,87.6848 202.269,95.6848 200,103.5C 192.665,114.055 182.832,117.222 170.5,113C 162.508,109.346 158.008,103.18 157,94.5C 156.501,89.5111 156.334,84.5111 156.5,79.5C 143.912,82.2495 133.412,78.9161 125,69.5C 119.77,58.3194 121.27,48.1527 129.5,39C 137.852,34.383 146.852,32.5497 156.5,33.5C 155.988,25.9947 156.822,18.6613 159,11.5C 161.85,5.51442 166.35,1.51442 172.5,-0.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b6f1fb'
					style={{ opacity: 1 }}
					d='M 176.5,14.5 C 181.28,13.6409 184.78,15.3075 187,19.5C 187.333,27.1667 187.667,34.8333 188,42.5C 189.122,44.6238 190.622,46.4571 192.5,48C 200.731,49.2729 209.064,49.9395 217.5,50C 224.167,54.6667 224.167,59.3333 217.5,64C 209.5,64.3333 201.5,64.6667 193.5,65C 191,66.1667 189.167,68 188,70.5C 187.667,78.5 187.333,86.5 187,94.5C 182.748,100.653 178.081,100.986 173,95.5C 172.942,86.7316 172.275,78.065 171,69.5C 169.363,67.1898 167.196,65.6898 164.5,65C 156.833,64.6667 149.167,64.3333 141.5,64C 136.167,59.3333 136.167,54.6667 141.5,50C 149.167,49.6667 156.833,49.3333 164.5,49C 168.333,47.8333 170.833,45.3333 172,41.5C 172.333,33.8333 172.667,26.1667 173,18.5C 174.376,17.2949 175.542,15.9616 176.5,14.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#010202'
					style={{ opacity: 0.954 }}
					d='M 336.5,97.5 C 334.071,97.415 332.071,96.415 330.5,94.5C 329.219,90.6247 327.886,86.7914 326.5,83C 321.18,80.2981 317.013,81.4648 314,86.5C 313.667,94.5 313.333,102.5 313,110.5C 311.52,113.663 309.02,115.496 305.5,116C 297.833,116.333 290.167,116.667 282.5,117C 279.353,120.309 278.687,124.142 280.5,128.5C 281.5,129.333 282.5,130.167 283.5,131C 292.242,131.089 300.909,131.756 309.5,133C 311.049,134.214 312.215,135.714 313,137.5C 313.333,145.167 313.667,152.833 314,160.5C 317.692,167.637 322.359,168.304 328,162.5C 328.333,154.833 328.667,147.167 329,139.5C 329.883,136.898 331.383,134.731 333.5,133C 341.731,131.727 350.064,131.06 358.5,131C 363.689,128.087 364.856,123.92 362,118.5C 358.276,117.059 354.443,115.893 350.5,115C 348.752,112.987 347.418,110.82 346.5,108.5C 347.183,104.98 349.183,102.48 352.5,101C 368.866,100.033 377.866,107.7 379.5,124C 378.864,135.29 373.198,142.624 362.5,146C 356.509,146.499 350.509,146.666 344.5,146.5C 345.275,155.143 344.108,163.476 341,171.5C 334.589,179.974 326.089,183.14 315.5,181C 306.219,178.719 300.719,172.886 299,163.5C 298.501,157.843 298.334,152.176 298.5,146.5C 292.491,146.666 286.491,146.499 280.5,146C 269.652,142.49 263.985,134.99 263.5,123.5C 264.32,110.844 270.987,103.344 283.5,101C 288.489,100.501 293.489,100.334 298.5,100.5C 296.102,88.5793 299.102,78.4127 307.5,70C 319.436,63.4533 330.269,64.9533 340,74.5C 343.549,80.0261 344.882,86.0261 344,92.5C 342.67,96.0067 340.17,97.6734 336.5,97.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b5f0fa'
					style={{ opacity: 0.998 }}
					d='M 336.5,97.5 C 334.64,106.178 337.973,109.845 346.5,108.5C 347.418,110.82 348.752,112.987 350.5,115C 354.443,115.893 358.276,117.059 362,118.5C 364.856,123.92 363.689,128.087 358.5,131C 350.064,131.06 341.731,131.727 333.5,133C 331.383,134.731 329.883,136.898 329,139.5C 328.667,147.167 328.333,154.833 328,162.5C 322.359,168.304 317.692,167.637 314,160.5C 313.667,152.833 313.333,145.167 313,137.5C 312.215,135.714 311.049,134.214 309.5,133C 300.909,131.756 292.242,131.089 283.5,131C 282.5,130.167 281.5,129.333 280.5,128.5C 278.687,124.142 279.353,120.309 282.5,117C 290.167,116.667 297.833,116.333 305.5,116C 309.02,115.496 311.52,113.663 313,110.5C 313.333,102.5 313.667,94.5 314,86.5C 317.013,81.4648 321.18,80.2981 326.5,83C 327.886,86.7914 329.219,90.6247 330.5,94.5C 332.071,96.415 334.071,97.415 336.5,97.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#020000'
					style={{ opacity: 0.96 }}
					d='M 363.5,436.5 C 361.278,432.904 361.111,429.237 363,425.5C 399.181,391.3 415.515,349.3 412,299.5C 406.97,267.24 390.804,242.407 363.5,225C 354.383,220.128 344.717,216.961 334.5,215.5C 307.159,212.42 282.159,218.92 259.5,235C 257.167,235.667 254.833,235.667 252.5,235C 221.636,214.017 188.636,209.684 153.5,222C 150.167,224 146.833,226 143.5,228C 141.167,228.667 138.833,228.667 136.5,228C 135.529,226.519 134.196,225.685 132.5,225.5C 132.184,222.022 132.684,218.688 134,215.5C 155.54,201.79 179.04,196.624 204.5,200C 222.973,202.242 239.973,208.409 255.5,218.5C 293.939,194.871 333.272,193.037 373.5,213C 403.491,232.449 421.324,259.949 427,295.5C 431.765,333.438 423.765,368.438 403,400.5C 394.304,413.535 384.471,425.702 373.5,437C 370.112,437.659 366.779,437.492 363.5,436.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#fe6066'
					style={{ opacity: 1 }}
					d='M 334.5,215.5 C 333.448,215.351 332.448,215.517 331.5,216C 366.878,235.21 386.378,265.043 390,305.5C 391.736,328.419 388.069,350.419 379,371.5C 362.469,405.367 339.303,433.867 309.5,457C 291.023,471.412 271.357,484.078 250.5,495C 254.152,495.499 257.818,495.665 261.5,495.5C 253.299,497.66 245.632,496.16 238.5,491C 200.519,471.422 167.019,445.922 138,414.5C 120.561,394.353 108.227,371.353 101,345.5C 92.9679,310.081 99.6346,277.748 121,248.5C 121.758,244.643 121.258,240.977 119.5,237.5C 123.807,233.528 128.141,229.528 132.5,225.5C 134.196,225.685 135.529,226.519 136.5,228C 138.833,228.667 141.167,228.667 143.5,228C 146.833,226 150.167,224 153.5,222C 188.636,209.684 221.636,214.017 252.5,235C 254.833,235.667 257.167,235.667 259.5,235C 282.159,218.92 307.159,212.42 334.5,215.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#fb4449'
					style={{ opacity: 1 }}
					d='M 334.5,215.5 C 344.717,216.961 354.383,220.128 363.5,225C 390.804,242.407 406.97,267.24 412,299.5C 415.515,349.3 399.181,391.3 363,425.5C 361.111,429.237 361.278,432.904 363.5,436.5C 359.133,440.868 354.467,444.868 349.5,448.5C 345.948,446.132 342.281,445.965 338.5,448C 319.588,462.902 299.588,476.236 278.5,488C 273.036,491.068 267.369,493.568 261.5,495.5C 257.818,495.665 254.152,495.499 250.5,495C 271.357,484.078 291.023,471.412 309.5,457C 339.303,433.867 362.469,405.367 379,371.5C 388.069,350.419 391.736,328.419 390,305.5C 386.378,265.043 366.878,235.21 331.5,216C 332.448,215.517 333.448,215.351 334.5,215.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#020000'
					style={{ opacity: 0.956 }}
					d='M 119.5,237.5 C 121.258,240.977 121.758,244.643 121,248.5C 99.6346,277.748 92.9679,310.081 101,345.5C 108.227,371.353 120.561,394.353 138,414.5C 167.019,445.922 200.519,471.422 238.5,491C 245.632,496.16 253.299,497.66 261.5,495.5C 267.369,493.568 273.036,491.068 278.5,488C 299.588,476.236 319.588,462.902 338.5,448C 342.281,445.965 345.948,446.132 349.5,448.5C 351.842,451.717 352.009,455.05 350,458.5C 323.758,479.868 295.258,497.535 264.5,511.5C 258.5,511.5 252.5,511.5 246.5,511.5C 197.837,489.898 156.004,458.898 121,418.5C 101.962,395.418 89.6286,369.085 84,339.5C 78.6503,302.065 87.1503,268.231 109.5,238C 112.753,236.18 116.087,236.013 119.5,237.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#040202'
					style={{ opacity: 1 }}
					d='M 172.5,248.5 C 187.321,245.909 197.155,251.576 202,265.5C 202.499,271.491 202.666,277.491 202.5,283.5C 208.509,283.334 214.509,283.501 220.5,284C 236.46,290.432 240.96,301.599 234,317.5C 230.635,322.186 226.135,325.353 220.5,327C 214.509,327.499 208.509,327.666 202.5,327.5C 203.275,336.143 202.108,344.476 199,352.5C 190.007,363.238 179.173,365.404 166.5,359C 161.964,355.138 159.131,350.305 158,344.5C 157.501,338.843 157.334,333.176 157.5,327.5C 151.491,327.666 145.491,327.499 139.5,327C 124.866,321.813 119.699,311.647 124,296.5C 126.892,290.608 131.392,286.441 137.5,284C 144.158,283.5 150.825,283.334 157.5,283.5C 153.83,267.776 158.83,256.109 172.5,248.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b3edf7'
					style={{ opacity: 1 }}
					d='M 175.5,263.5 C 180.223,262.553 184.056,263.886 187,267.5C 187.333,275.167 187.667,282.833 188,290.5C 188.346,292.848 189.513,294.681 191.5,296C 199.731,297.273 208.064,297.94 216.5,298C 224.393,302.91 224.393,307.91 216.5,313C 208.064,313.06 199.731,313.727 191.5,315C 189.951,316.214 188.785,317.714 188,319.5C 187.667,327.167 187.333,334.833 187,342.5C 184.565,347.224 180.731,348.724 175.5,347C 174.299,346.097 173.465,344.931 173,343.5C 172.667,335.833 172.333,328.167 172,320.5C 171.214,317.417 169.381,315.251 166.5,314C 158.5,313.667 150.5,313.333 142.5,313C 137.465,309.987 136.298,305.82 139,300.5C 139.903,299.299 141.069,298.465 142.5,298C 150.5,297.667 158.5,297.333 166.5,297C 169.187,295.125 171.021,292.625 172,289.5C 172.333,281.833 172.667,274.167 173,266.5C 174.045,265.627 174.878,264.627 175.5,263.5 Z'
				/>
			</g>
		</svg>
	);
};
