import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getGuildTasks,
	getGuildTasksAsync,
	getReferralsCount,
	getReferralsCountAsync,
	takeGuildTask,
	takeGuildTaskAsync,
	clearGuildError,
	clearGuildErrorAsync,
} from '../../actions/guild';

import guildApi from '../../../services/api/guild';

function clearErrorWorker() {
	return {};
}

export function* guildSaga() {
	yield takeEvery(getGuildTasks, bindAsyncActions(getGuildTasksAsync)(guildApi.getGuildTasksEndpoint));
	yield takeEvery(getReferralsCount, bindAsyncActions(getReferralsCountAsync)(guildApi.getReferralsCountEndpoint));
	yield takeEvery(takeGuildTask, bindAsyncActions(takeGuildTaskAsync)(guildApi.takeGuildTaskEndpoint));
	yield takeEvery(clearGuildError, bindAsyncActions(clearGuildErrorAsync)(clearErrorWorker));
}
