import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import AvatarRenderer from '../../components/AvatarRenderer';
import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		appearance: s.avatarManager.state.appearance,
		equipment: s.avatarManager.state.equipment,
		wearable: s.avatarManager.state.wearable,
		gender: s.avatarManager.state.gender,
		race: s.avatarManager.state.race,
	}),
	{ openModal }
)(({ appearance, equipment, wearable, gender, race, openModal }) => {
	const { t } = useTranslation();

	return (
		<div className={classNames({ container: true, 'avatar-equipment': true })}>
			<MainHeader title={t('avatar-equipment.header.title')} />
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'avatar-wrapper': true })}>
					<AvatarRenderer
						appearance={appearance}
						equipment={equipment}
						withWearable={true}
						wearable={wearable}
						gender={gender}
						race={race}
					/>
				</div>
				<div className={classNames({ equipments: true })}>
					<div className={classNames({ 'equipment-row': true })}>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'body' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.body ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.body.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.body')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'leggs' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.leggs ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.leggs.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.leggs')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'boots' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.boots ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.boots.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.boots')}</span>
						</div>
					</div>
					<div className={classNames({ 'equipment-row': true })}>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'pickaxe' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.pickaxe ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.pickaxe.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.pickaxe')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'shovel' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.shovel ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.shovel.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.shovel')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'axe' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.axe ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.axe.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.axe')}</span>
						</div>
					</div>
					<div className={classNames({ 'equipment-row': true })}>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'leftArm' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.leftArm ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.leftArm.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.left-arm')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'rightArm' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.rightArm ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.rightArm.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.right-arm')}</span>
						</div>
						<div
							onClick={() => openModal({ name: 'equipment-inventory', payload: { type: 'full', equipmentType: 'backpack' } })}
							className={classNames({ equipment: true })}
						>
							{equipment.backpack ? (
								<img
									className={classNames({ 'equipment-image': true })}
									src={equipment.backpack.image}
								/>
							) : (
								<div className={classNames({ 'equipment-image': true })}></div>
							)}
							<span className={classNames({ 'equipment-name': true })}>{t('avatar-equipment.backpack')}</span>
						</div>
					</div>
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
