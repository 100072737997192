import axios from 'axios';

import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	static getGuildTasksEndpoint = () => axios.get(`${api_url}guild/tasks`);
	static getReferralsCountEndpoint = () => axios.get(`${api_url}guild/referrals`);
	static takeGuildTaskEndpoint = (data) => axios.post(`${api_url}guild/tasks/take`, data);
}
