import React from 'react';

export default ({ width = 512, height = 512 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 512 512'
			style={{
				minWidth: `${width}px`,
				minHeight: `${height}px`,
				shapeRendering: 'geometricPrecision',
				textRendering: 'geometricPrecision',
				imageRendering: 'optimizeQuality',
				fillRule: 'evenodd',
				clipRule: 'evenodd',
			}}
		>
			<g>
				<path
					fill='#010201'
					style={{ opacity: 0.971 }}
					d='M 192.5,-0.5 C 200.167,-0.5 207.833,-0.5 215.5,-0.5C 217.578,1.39043 218.911,3.72376 219.5,6.5C 218.59,9.79257 216.59,12.2926 213.5,14C 207.833,14.3333 202.167,14.6667 196.5,15C 191.921,16.7435 188.921,19.9102 187.5,24.5C 186.566,25.5676 186.232,26.901 186.5,28.5C 232.5,28.5 278.5,28.5 324.5,28.5C 324.768,26.901 324.434,25.5676 323.5,24.5C 322.079,19.9102 319.079,16.7435 314.5,15C 290.833,14.6667 267.167,14.3333 243.5,14C 240.292,12.5627 238.625,10.0627 238.5,6.5C 238.688,3.79385 239.688,1.46052 241.5,-0.5C 267.167,-0.5 292.833,-0.5 318.5,-0.5C 331.606,4.45072 338.94,13.9507 340.5,28C 356.121,31.2872 365.287,40.7872 368,56.5C 368.909,70.5472 368.575,84.5472 367,98.5C 363,110.5 355,118.5 343,122.5C 342.333,128.167 342.333,133.833 343,139.5C 344.25,142.171 346.083,144.337 348.5,146C 385.933,146.797 411.099,164.63 424,199.5C 425.515,205.075 426.515,210.742 427,216.5C 427.667,291.833 427.667,367.167 427,442.5C 422.116,479.545 401.616,502.545 365.5,511.5C 292.167,511.5 218.833,511.5 145.5,511.5C 113.296,503.796 93.1289,483.796 85,451.5C 83.36,373.188 83.0266,294.855 84,216.5C 87.5685,184.851 104.069,163.018 133.5,151C 143.033,148.512 152.699,146.845 162.5,146C 164.333,144.833 165.833,143.333 167,141.5C 168.581,135.277 168.915,128.944 168,122.5C 156,118.5 148,110.5 144,98.5C 142.425,84.5472 142.091,70.5472 143,56.5C 145.726,40.7744 154.892,31.2744 170.5,28C 172.06,13.9507 179.394,4.45072 192.5,-0.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#bb7b62'
					style={{ opacity: 0.999 }}
					d='M 235.5,6.5 C 236.5,6.5 237.5,6.5 238.5,6.5C 238.625,10.0627 240.292,12.5627 243.5,14C 267.167,14.3333 290.833,14.6667 314.5,15C 319.079,16.7435 322.079,19.9102 323.5,24.5C 323.5,25.5 323.5,26.5 323.5,27.5C 288.5,27.5 253.5,27.5 218.5,27.5C 219.359,16.6385 225.025,9.6385 235.5,6.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#aa685c'
					style={{ opacity: 1 }}
					d='M 219.5,6.5 C 224.833,6.5 230.167,6.5 235.5,6.5C 225.025,9.6385 219.359,16.6385 218.5,27.5C 208.167,27.5 197.833,27.5 187.5,27.5C 187.5,26.5 187.5,25.5 187.5,24.5C 188.921,19.9102 191.921,16.7435 196.5,15C 202.167,14.6667 207.833,14.3333 213.5,14C 216.59,12.2926 218.59,9.79257 219.5,6.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#483027'
					style={{ opacity: 1 }}
					d='M 187.5,24.5 C 187.5,25.5 187.5,26.5 187.5,27.5C 197.833,27.5 208.167,27.5 218.5,27.5C 253.5,27.5 288.5,27.5 323.5,27.5C 323.5,26.5 323.5,25.5 323.5,24.5C 324.434,25.5676 324.768,26.901 324.5,28.5C 278.5,28.5 232.5,28.5 186.5,28.5C 186.232,26.901 186.566,25.5676 187.5,24.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#96e0f6'
					style={{ opacity: 1 }}
					d='M 338.5,43.5 C 296.499,43.3333 254.499,43.5 212.5,44C 209.433,47.2975 207.267,51.1308 206,55.5C 205.333,68.8333 205.333,82.1667 206,95.5C 207.402,99.9748 209.569,103.975 212.5,107.5C 198.496,107.667 184.496,107.5 170.5,107C 165.333,104.5 161.5,100.667 159,95.5C 158.333,82.1667 158.333,68.8333 159,55.5C 161.263,48.9028 165.763,44.7361 172.5,43C 228.002,42.1682 283.335,42.3348 338.5,43.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#c7eefd'
					style={{ opacity: 1 }}
					d='M 338.5,43.5 C 345.318,44.8147 349.818,48.8147 352,55.5C 352.667,68.8333 352.667,82.1667 352,95.5C 349.309,101.529 344.809,105.362 338.5,107C 296.501,107.5 254.501,107.667 212.5,107.5C 209.569,103.975 207.402,99.9748 206,95.5C 205.333,82.1667 205.333,68.8333 206,55.5C 207.267,51.1308 209.433,47.2975 212.5,44C 254.499,43.5 296.499,43.3333 338.5,43.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#73adbe'
					style={{ opacity: 1 }}
					d='M 327.5,140.5 C 326.511,135.025 326.178,129.358 326.5,123.5C 279.167,123.5 231.833,123.5 184.5,123.5C 184.821,129.026 184.488,134.36 183.5,139.5C 183.5,133.833 183.5,128.167 183.5,122.5C 231.5,122.5 279.5,122.5 327.5,122.5C 327.5,128.5 327.5,134.5 327.5,140.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#99e5fb'
					style={{ opacity: 1 }}
					d='M 327.5,140.5 C 327.5,140.833 327.5,141.167 327.5,141.5C 287.5,141.5 247.5,141.5 207.5,141.5C 207.51,146.638 205.51,150.805 201.5,154C 182.661,156 170.661,166.167 165.5,184.5C 147.992,184.83 130.659,184.497 113.5,183.5C 126.638,168.438 143.304,161.272 163.5,162C 175.295,159.04 181.961,151.54 183.5,139.5C 184.488,134.36 184.821,129.026 184.5,123.5C 231.833,123.5 279.167,123.5 326.5,123.5C 326.178,129.358 326.511,135.025 327.5,140.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#c7eefd'
					style={{ opacity: 1 }}
					d='M 327.5,141.5 C 329.83,151.999 336.164,158.832 346.5,162C 367.048,161.117 384.048,168.283 397.5,183.5C 389.5,184.167 381.5,184.833 373.5,185.5C 369.41,186.002 367.41,188.335 367.5,192.5C 360.833,192.5 354.167,192.5 347.5,192.5C 346.932,188.933 344.932,186.599 341.5,185.5C 283.002,184.501 224.336,184.168 165.5,184.5C 170.661,166.167 182.661,156 201.5,154C 205.51,150.805 207.51,146.638 207.5,141.5C 247.5,141.5 287.5,141.5 327.5,141.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#8caeb9'
					style={{ opacity: 1 }}
					d='M 113.5,183.5 C 130.659,184.497 147.992,184.83 165.5,184.5C 224.336,184.168 283.002,184.501 341.5,185.5C 265.164,185.833 188.83,185.5 112.5,184.5C 112.624,183.893 112.957,183.56 113.5,183.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#93afba'
					style={{ opacity: 1 }}
					d='M 397.5,183.5 C 398.043,183.56 398.376,183.893 398.5,184.5C 390.193,185.498 381.86,185.831 373.5,185.5C 381.5,184.833 389.5,184.167 397.5,183.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#9ae59c'
					style={{ opacity: 1 }}
					d='M 347.5,192.5 C 354.167,192.5 360.833,192.5 367.5,192.5C 367.544,196.09 369.211,198.59 372.5,200C 384.495,200.5 396.495,200.667 408.5,200.5C 409.224,203.843 410.057,207.176 411,210.5C 411.667,288.5 411.667,366.5 411,444.5C 405.833,473.667 388.667,490.833 359.5,496C 299.168,496.5 238.834,496.667 178.5,496.5C 171.508,489.52 166.841,481.186 164.5,471.5C 182.033,458.851 185.199,443.184 174,424.5C 169.739,419.735 164.572,416.401 158.5,414.5C 158.169,392.327 158.503,370.327 159.5,348.5C 161.795,355.928 164.795,363.095 168.5,370C 160.188,388.057 164.521,402.057 181.5,412C 186.401,413.325 191.401,413.825 196.5,413.5C 193.74,430.827 200.407,442.66 216.5,449C 225.231,450.09 233.064,447.923 240,442.5C 251.838,451.799 263.671,451.799 275.5,442.5C 288.516,452.303 301.016,451.636 313,440.5C 316.23,436.375 318.063,431.708 318.5,426.5C 319.483,422.702 319.817,418.702 319.5,414.5C 320.791,414.737 321.791,414.404 322.5,413.5C 340.369,409.78 348.535,398.78 347,380.5C 346.171,376.839 344.671,373.505 342.5,370.5C 359.666,337.21 359.833,303.877 343,270.5C 320.463,234.812 287.963,217.979 245.5,220C 202.016,226.655 173.349,250.822 159.5,292.5C 158.21,261.986 158.21,231.32 159.5,200.5C 220.168,200.667 280.834,200.5 341.5,200C 345.062,198.692 347.062,196.192 347.5,192.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#6ed6a2'
					style={{ opacity: 1 }}
					d='M 159.5,200.5 C 158.21,231.32 158.21,261.986 159.5,292.5C 156.948,297.93 156.948,303.264 159.5,308.5C 158.167,316.167 158.167,323.833 159.5,331.5C 158.03,333.159 157.363,335.159 157.5,337.5C 158.011,341.207 158.678,344.874 159.5,348.5C 158.503,370.327 158.169,392.327 158.5,414.5C 140.221,410.722 127.388,417.389 120,434.5C 116.3,453.601 123.467,466.768 141.5,474C 149.445,475.194 157.112,474.36 164.5,471.5C 166.841,481.186 171.508,489.52 178.5,496.5C 149.988,501.503 126.821,492.837 109,470.5C 104.206,462.452 101.206,453.785 100,444.5C 99.3333,366.5 99.3333,288.5 100,210.5C 100.943,207.176 101.776,203.843 102.5,200.5C 121.5,200.5 140.5,200.5 159.5,200.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#030403'
					style={{ opacity: 1 }}
					d='M 369.5,214.5 C 384.927,213.289 395.093,219.955 400,234.5C 401.306,250.525 394.14,260.358 378.5,264C 358.069,263.559 348.902,253.059 351,232.5C 354.441,223.557 360.608,217.557 369.5,214.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#040504'
					style={{ opacity: 1 }}
					d='M 322.5,413.5 C 321.167,413.5 319.833,413.5 318.5,413.5C 318.5,417.833 318.5,422.167 318.5,426.5C 318.063,431.708 316.23,436.375 313,440.5C 301.016,451.636 288.516,452.303 275.5,442.5C 263.671,451.799 251.838,451.799 240,442.5C 233.064,447.923 225.231,450.09 216.5,449C 200.407,442.66 193.74,430.827 196.5,413.5C 191.401,413.825 186.401,413.325 181.5,412C 164.521,402.057 160.188,388.057 168.5,370C 164.795,363.095 161.795,355.928 159.5,348.5C 158.678,344.874 158.011,341.207 157.5,337.5C 157.363,335.159 158.03,333.159 159.5,331.5C 160.708,330.766 162.041,330.433 163.5,330.5C 166.652,329.959 169.152,330.959 171,333.5C 173.472,345.772 178.139,357.106 185,367.5C 185.667,369.5 185.667,371.5 185,373.5C 179.526,378.955 178.193,385.288 181,392.5C 182.663,394.917 184.829,396.75 187.5,398C 193.5,398.333 199.5,398.667 205.5,399C 207.624,400.122 209.457,401.622 211,403.5C 211.333,411.167 211.667,418.833 212,426.5C 216.454,435.448 222.62,436.782 230.5,430.5C 231.887,427.508 233.554,424.675 235.5,422C 238.5,420 241.5,420 244.5,422C 246.273,426.332 248.94,429.998 252.5,433C 260.74,435.63 266.24,432.796 269,424.5C 272.143,420.039 275.977,419.372 280.5,422.5C 282.198,426.438 284.532,429.938 287.5,433C 293.942,435.889 299.108,434.389 303,428.5C 303.969,419.473 304.969,410.473 306,401.5C 306.903,400.299 308.069,399.465 309.5,399C 314.5,398.667 319.5,398.333 324.5,398C 334.003,391.14 334.836,383.306 327,374.5C 326.833,373.333 326.667,372.167 326.5,371C 330.712,362.065 334.546,352.899 338,343.5C 347.324,297.656 331.49,263.823 290.5,242C 244.401,225.811 207.901,237.977 181,278.5C 176.08,287.259 172.747,296.592 171,306.5C 169.117,309.024 166.617,310.358 163.5,310.5C 162.167,309.833 160.833,309.167 159.5,308.5C 156.948,303.264 156.948,297.93 159.5,292.5C 173.349,250.822 202.016,226.655 245.5,220C 287.963,217.979 320.463,234.812 343,270.5C 359.833,303.877 359.666,337.21 342.5,370.5C 344.671,373.505 346.171,376.839 347,380.5C 348.535,398.78 340.369,409.78 322.5,413.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b4eb95'
					style={{ opacity: 1 }}
					d='M 371.5,229.5 C 382.176,229.328 386.343,234.328 384,244.5C 377.471,251.493 371.471,251.159 366,243.5C 364.268,237.314 366.101,232.647 371.5,229.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#f7f4f4'
					style={{ opacity: 1 }}
					d='M 163.5,330.5 C 163.5,323.833 163.5,317.167 163.5,310.5C 166.617,310.358 169.117,309.024 171,306.5C 172.747,296.592 176.08,287.259 181,278.5C 207.901,237.977 244.401,225.811 290.5,242C 331.49,263.823 347.324,297.656 338,343.5C 334.546,352.899 330.712,362.065 326.5,371C 326.667,372.167 326.833,373.333 327,374.5C 334.836,383.306 334.003,391.14 324.5,398C 319.5,398.333 314.5,398.667 309.5,399C 308.069,399.465 306.903,400.299 306,401.5C 304.969,410.473 303.969,419.473 303,428.5C 299.108,434.389 293.942,435.889 287.5,433C 284.532,429.938 282.198,426.438 280.5,422.5C 275.977,419.372 272.143,420.039 269,424.5C 266.24,432.796 260.74,435.63 252.5,433C 248.94,429.998 246.273,426.332 244.5,422C 241.5,420 238.5,420 235.5,422C 233.554,424.675 231.887,427.508 230.5,430.5C 222.62,436.782 216.454,435.448 212,426.5C 211.667,418.833 211.333,411.167 211,403.5C 209.457,401.622 207.624,400.122 205.5,399C 199.5,398.667 193.5,398.333 187.5,398C 184.829,396.75 182.663,394.917 181,392.5C 178.193,385.288 179.526,378.955 185,373.5C 185.667,371.5 185.667,369.5 185,367.5C 178.139,357.106 173.472,345.772 171,333.5C 169.152,330.959 166.652,329.959 163.5,330.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#a0e5a1'
					style={{ opacity: 1 }}
					d='M 159.5,308.5 C 160.833,309.167 162.167,309.833 163.5,310.5C 163.5,317.167 163.5,323.833 163.5,330.5C 162.041,330.433 160.708,330.766 159.5,331.5C 158.167,323.833 158.167,316.167 159.5,308.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#040404'
					style={{ opacity: 1 }}
					d='M 214.5,320.5 C 239.526,319.031 252.026,330.698 252,355.5C 246.494,374.983 233.66,383.15 213.5,380C 197.281,373.74 190.115,361.907 192,344.5C 195.245,332.424 202.745,324.424 214.5,320.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#040504'
					style={{ opacity: 1 }}
					d='M 281.5,320.5 C 299.858,318.053 312.358,325.386 319,342.5C 321.018,370.148 308.185,382.648 280.5,380C 260.941,370.668 255.108,355.834 263,335.5C 267.592,328.394 273.758,323.394 281.5,320.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#97e099'
					style={{ opacity: 1 }}
					d='M 216.5,335.5 C 232.499,334.523 238.999,341.856 236,357.5C 227.753,368.631 218.753,369.298 209,359.5C 204.325,349.198 206.825,341.198 216.5,335.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#98e29a'
					style={{ opacity: 1 }}
					d='M 284.5,335.5 C 300.913,335.071 307.08,342.737 303,358.5C 292.286,369.824 282.952,368.824 275,355.5C 273.011,346.338 276.177,339.671 284.5,335.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#030403'
					style={{ opacity: 1 }}
					d='M 126.5,361.5 C 146.857,360.688 156.023,370.355 154,390.5C 148.88,401.978 140.046,407.145 127.5,406C 111.849,399.531 107.016,388.364 113,372.5C 116.336,367.269 120.836,363.602 126.5,361.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#040504'
					style={{ opacity: 1 }}
					d='M 251.5,372.5 C 259.039,372.02 265.205,374.687 270,380.5C 272.921,385.341 275.588,390.341 278,395.5C 279.085,403.496 276.251,409.663 269.5,414C 260.911,415.576 252.244,415.91 243.5,415C 235.336,410.505 232.17,403.672 234,394.5C 236.599,388.911 239.599,383.577 243,378.5C 245.637,376.109 248.47,374.109 251.5,372.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b4eb95'
					style={{ opacity: 1 }}
					d='M 129.5,376.5 C 137.775,375.706 140.942,379.373 139,387.5C 134.667,392.833 130.333,392.833 126,387.5C 124.663,382.872 125.83,379.205 129.5,376.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#8ed28f'
					style={{ opacity: 1 }}
					d='M 254.5,387.5 C 256.017,387.511 257.184,388.177 258,389.5C 259.579,392.994 261.413,396.327 263.5,399.5C 263.291,400.086 262.957,400.586 262.5,401C 257.913,401.799 253.413,401.632 249,400.5C 248.333,399.5 248.333,398.5 249,397.5C 251.112,394.273 252.945,390.94 254.5,387.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#030503'
					style={{ opacity: 1 }}
					d='M 158.5,414.5 C 164.572,416.401 169.739,419.735 174,424.5C 185.199,443.184 182.033,458.851 164.5,471.5C 157.112,474.36 149.445,475.194 141.5,474C 123.467,466.768 116.3,453.601 120,434.5C 127.388,417.389 140.221,410.722 158.5,414.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#3f5e40'
					style={{ opacity: 1 }}
					d='M 322.5,413.5 C 321.791,414.404 320.791,414.737 319.5,414.5C 319.817,418.702 319.483,422.702 318.5,426.5C 318.5,422.167 318.5,417.833 318.5,413.5C 319.833,413.5 321.167,413.5 322.5,413.5 Z'
				/>
			</g>
			<g>
				<path
					fill='#b7ef98'
					style={{ opacity: 1 }}
					d='M 143.5,428.5 C 160.341,426.834 167.174,434.167 164,450.5C 159.849,458.34 153.349,461.173 144.5,459C 134.46,453.756 131.626,445.923 136,435.5C 138.396,432.933 140.896,430.6 143.5,428.5 Z'
				/>
			</g>
		</svg>
	);
};
