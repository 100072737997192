import { takeEvery } from 'redux-saga/effects';

import { bindAsyncActions } from '../../../utils/store/helpers';

import {
	getTownhallNews,
	getTownhallNewsAsync,
	getTownhallFundraisers,
	getTownhallFundraisersAsync,
	townhallFundraisersDonate,
	townhallFundraisersDonateAsync,
	updateTownhallFundraiser,
	updateTownhallFundraiserAsync,
	clearTownhallError,
	clearTownhallErrorAsync,
} from '../../actions/townhall';

import townhallApi from '../../../services/api/townhall';

function clearErrorWorker() {
	return {};
}

function updateTownhallFundraiserWorker(response) {
	return { response };
}

export function* townhallSaga() {
	yield takeEvery(getTownhallNews, bindAsyncActions(getTownhallNewsAsync)(townhallApi.getTownhallNewsEndpoint));
	yield takeEvery(
		getTownhallFundraisers,
		bindAsyncActions(getTownhallFundraisersAsync)(townhallApi.getTownhallFundraisersEndpoint)
	);
	yield takeEvery(
		townhallFundraisersDonate,
		bindAsyncActions(townhallFundraisersDonateAsync)(townhallApi.townhallFundraisersDonateEndpoint)
	);
	yield takeEvery(updateTownhallFundraiser, bindAsyncActions(updateTownhallFundraiserAsync)(updateTownhallFundraiserWorker));
	yield takeEvery(clearTownhallError, bindAsyncActions(clearTownhallErrorAsync)(clearErrorWorker));
}
