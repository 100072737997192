import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import MainHeader from '../../components/MainHeader';
import MainNavbar from '../../components/MainNavbar';

import { openModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		inventory: s.inventoryManager.avatar,
	}),
	{ openModal }
)(({ inventory, openModal }) => {
	const [Items, SetItems] = React.useState([]);
	const { t } = useTranslation();

	React.useEffect(() => {
		const formattedItems = [];
		const itemMap = new Map();

		for (const item of inventory.items) {
			if (item.stackable) {
				if (itemMap.has(item.id)) {
					itemMap.get(item.id).count += 1;
				} else {
					itemMap.set(item.id, { item: item, count: 1 });
				}
			} else {
				formattedItems.push({ item: item, count: 1 });
			}
		}

		for (const formattedItem of itemMap.values()) {
			formattedItems.push(formattedItem);
		}

		SetItems(formattedItems);
	}, [inventory]);

	return (
		<div className={classNames({ container: true, inventory: true })}>
			<MainHeader
				title={t('inventory.header.title', {
					current: inventory.capacity.current.toFixed(1),
					maximum: inventory.capacity.maximum.toFixed(1),
				})}
			/>
			<div className={classNames({ content: true })}>
				<div className={classNames({ items: true })}>
					{Items.map((v, i) => (
						<div
							onClick={() => openModal({ name: 'inventory-item-info', payload: { type: 'full', item: v.item } })}
							className={classNames({ item: true })}
							key={i}
						>
							<img
								className={classNames({ 'item-image': true })}
								src={v.item.image}
							/>
							<span className={classNames({ 'item-count': true })}>x{v.count}</span>
						</div>
					))}
				</div>
			</div>
			<MainNavbar openModal={openModal} />
		</div>
	);
});
