import { combineReducers } from 'redux';

import createAvatarReducer from './createAvatar';
import guesthouseReducer from './guesthouse';
import inventoryReducer from './inventory';
import locationsReducer from './locations';
import townhallReducer from './townhall';
import settingsReducer from './settings';
import craftsReducer from './crafts';
import avatarReducer from './avatar';
import modalsReducer from './modals';
import guildReducer from './guild';
import bankReducer from './bank';
import userReducer from './user';

export default () =>
	combineReducers({
		createAvatarManager: createAvatarReducer,
		guesthouseManager: guesthouseReducer,
		inventoryManager: inventoryReducer,
		locationsManager: locationsReducer,
		townhallManager: townhallReducer,
		craftsManager: craftsReducer,
		avatarManager: avatarReducer,
		guildManager: guildReducer,
		settings: settingsReducer,
		userManager: userReducer,
		bankManager: bankReducer,
		modals: modalsReducer,
	});
