import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';
import DefaultButton from '../../Buttons/DefaultButton';

import { itemStats } from '../../../common/items';

const items = new Array(60).fill(
	{
		image: 'https://i.imgur.com/nAHaMP2.png',
		name: { ru: 'Древний серебряный меч', en: 'Древний серебряный меч' },
		description: {
			ru: 'Меч созданный в древних кузницах дворфов. Способен вызывать молнию',
			en: 'Меч созданный в древних кузницах дворфов. Способен вызывать молнию',
		},
		durability: { current: 5643, maximum: 6000 },
		damage: 123,
		weight: 12,
		count: 1,
		requirements: [
			{ name: 'avatar-level', value: 12 },
			{ name: 'combat-level', value: 12 },
		],
	},
	0,
	60
);

export default ({ inventory, closeModal }) => {
	const [PickedItem, SetPickedItem] = React.useState();
	const inventoryWrapperRef = React.useRef(null);
	const itemInfoWrapperRef = React.useRef(null);
	const [Items, SetItems] = React.useState([]);
	const { t, i18n } = useTranslation();

	React.useEffect(() => {
		const formattedItems = [];
		const itemMap = new Map();

		for (const item of inventory.items) {
			if (item.stackable) {
				if (itemMap.has(item.id)) {
					itemMap.get(item.id).count += 1;
				} else {
					itemMap.set(item.id, { item: item, count: 1 });
				}
			} else {
				formattedItems.push({ item: item, count: 1 });
			}
		}

		for (const formattedItem of itemMap.values()) {
			formattedItems.push(formattedItem);
		}

		SetItems(formattedItems);
	}, [inventory]);

	React.useEffect(() => {
		if (PickedItem && itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// up item info wrapper
			itemInfoWrapperRef.current.style.zIndex = 1;

			// down item info wrapper
			inventoryWrapperRef.current.style.zIndex = -1;
		} else if (itemInfoWrapperRef.current && inventoryWrapperRef.current) {
			// down item info wrapper
			itemInfoWrapperRef.current.style.zIndex = -1;

			// up item info wrapper
			inventoryWrapperRef.current.style.zIndex = 1;
		}
	}, [PickedItem]);

	return (
		<div className={classNames({ modal: true, 'location-inventory': true })}>
			<div
				className={classNames({ 'item-info-wrapper': true })}
				ref={(ref) => (itemInfoWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={() => SetPickedItem(null)}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>{t('location-inventory-modal.header.title-2')}</span>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ 'item-info': true })}>
						<img
							className={classNames({ 'item-image': true })}
							src={PickedItem?.image}
						/>
						<div className={classNames({ 'item-stats': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true, center: true })}>
								{PickedItem?.name[i18n.languages[0]]}
							</span>
							<span className={classNames({ 'item-stats-text': true })}>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`location-inventory-modal.stats-names.description`)}
								</span>
								{PickedItem?.description[i18n.languages[0]]}
							</span>
							{itemStats[PickedItem?.type]?.map((v, i) => (
								<span
									className={classNames({ 'item-stats-text': true })}
									key={i}
								>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`location-inventory-modal.stats-names.${v}`)}
									</span>
									{t(
										`location-inventory-modal.stats.${v}`,
										typeof PickedItem?.[v.split('-')[0]] == 'object'
											? { ...PickedItem?.[v.split('-')[0]] }
											: { value: v == 'rarity' ? t(`location-inventory-modal.rarities.${PickedItem?.[v]}`) : PickedItem?.[v] }
									)}
								</span>
							))}
							{PickedItem?.requires ? (
								<span className={classNames({ 'item-stats-text': true })}>
									<span className={classNames({ 'item-stats-text': true, strong: true })}>
										{t(`location-inventory-modal.stats-names.requirements`)}
									</span>
									{PickedItem?.requires
										.map((v) => t(`location-inventory-modal.requirements.${v.name}`, { value: v.value }))
										.join(', ')}
								</span>
							) : null}
						</div>
					</div>
				</div>
				{/* <div className={classNames({ 'action-button': true })}>
					<DefaultButton
						text={t('location-inventory-modal.buttons.use-item')}
						onClick={closeModal}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				</div> */}
			</div>
			<div
				className={classNames({ 'inventory-wrapper': true })}
				ref={(ref) => (inventoryWrapperRef.current = ref)}
			>
				<div className={classNames({ 'modal-header': true })}>
					<div
						className={classNames({ 'modal-header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
					<span className={classNames({ 'modal-header-title': true })}>
						{t('location-inventory-modal.header.title-1', {
							current: inventory.capacity.current.toFixed(1),
							maximum: inventory.capacity.maximum.toFixed(1),
						})}
					</span>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ items: true })}>
						{Items.map((v, i) => (
							<div
								className={classNames({ item: true })}
								onClick={() => SetPickedItem(v.item)}
								key={i}
							>
								<img
									className={classNames({ 'item-image': true })}
									src={v.item.image}
								/>
								<span className={classNames({ 'item-count': true })}>x{v.count}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
