import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import InverseLogoIcon from '../../components/Icons/InverseLogoIcon';
import DefaultButton from '../../components/Buttons/DefaultButton';

export default connect(
	(s) => ({}),
	{}
)(({}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={classNames({ container: true, welcome: true })}>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'icon-wrapper': true })}>
					<InverseLogoIcon
						width={220}
						height={220}
					/>
				</div>
				<span className={classNames({ title: true })}>{t('welcome-page.title')}</span>
				<span className={classNames({ description: true })}>{t('welcome-page.description')}</span>
			</div>
			<div className={classNames({ 'bottom-buttons': true })}>
				<DefaultButton
					text={t('welcome-page.buttons.create')}
					onClick={() => navigate('/create-avatar-gender-race')}
				/>
			</div>
		</div>
	);
});
