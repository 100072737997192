{
	"welcome-page": {
		"title": "SYNERGY: HERO",
		"description": "Создайте аватара. Творите. Станьте тем, кем хотите. Спасите этот мир",
		"buttons": {
			"create": "Создать аватара"
		}
	},
	"something-went-wrong-page": {
		"title": "Что-то пошло не так",
		"description": "Возникла непредвиденная ошибка, попробуйте зайти немного поздней"
	},
	"not-white-listed-page": {
		"title": "Вас нет в белом списке",
		"description": "Для доступа к игре в данный момент необходимо получить доступ, для этого следите за новостями в канале проекта",
		"buttons": {
			"to-channel": "Перейти в канал проекта"
		}
	},
	"create-avatar-gender-race": {
		"pick-gender": "Выберите пол",
		"genders": {
			"male": "Мужчина",
			"female": "Женщина"
		},
		"pick-race": "Выберите расу",
		"races": {
			"human": "Человек",
			"elve": "Эльф",
			"dwarve": "Дворф",
			"demon": "Демон"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-head-body": {
		"pick-head": "Выберите голову",
		"heads": {
			"small": "Маленькое",
			"middle": "Среднее",
			"big": "Большое"
		},
		"pick-body": "Выберите тело",
		"bodies": {
			"small": "Маленькое",
			"middle": "Среднее",
			"big": "Большое"
		},
		"pick-skin-color": "Выберите цвет кожи",
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-hairs": {
		"pick-hairs-front": "Выберите чёлку",
		"pick-hairs-back": "Выберите причёску",
		"pick-hairs-color": "Выберите цвет волос",
		"type": "Тип {{type}}",
		"modals": {
			"hairs-front": "Выберите чёлку",
			"hairs-back": "Выберите причёску"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-eyebrows-eyes-mouth": {
		"pick-eyebrows": "Выберите брови",
		"pick-eyes-color": "Выберите цвет глаз",
		"pick-mouth": "Выберите рот",
		"type": "Тип {{type}}",
		"modals": {
			"eyebrows": "Выберите брови",
			"mouth": "Выберите рот"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-beard": {
		"pick-beard": "Выберите бороду",
		"pick-beard-color": "Выберите цвет бороды",
		"type": "Тип {{type}}",
		"none": "Нет",
		"modals": {
			"beard": "Выберите бороду"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-horns-wings-tail": {
		"pick-horns-color": "Выберите цвет рогов",
		"pick-wings-color": "Выберите цвет крыльев",
		"pick-tail": "Выберите хвост",
		"type": "Тип {{type}}",
		"modals": {
			"tail": "Выберите хвост"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-wearable-body": {
		"pick-body": "Выберите верхнюю одежду",
		"pick-body-color": "Выберите цвет одежды",
		"bodies": {
			"0": "Майка",
			"1": "Кофта"
		},
		"modals": {
			"body": "Выберите верхнюю одежду"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-wearable-leggs-boots": {
		"pick-leggs": "Выберите нижнюю одежду",
		"pick-leggs-color": "Выберите цвет одежды",
		"pick-boots": "Выберите обувь",
		"leggs": {
			"0": "Узкие штаны",
			"1": "Широкие штаны"
		},
		"boots": {
			"0": "Высокие ботинки",
			"1": "Низкие ботинки"
		},
		"modals": {
			"leggs": "Выберите нижнюю одежду",
			"boots": "Выберите обувь"
		},
		"buttons": {
			"back": "Назад",
			"next": "Далее"
		}
	},
	"create-avatar-confirm": {
		"enter-avatar-name": "Введите имя персонажа",
		"avatar-name": "Имя персонажа",
		"buttons": {
			"back": "Назад",
			"confirm": "Подтвердить создание"
		}
	},

	"main-navbar": {
		"avatar": "Аватар",
		"map": "Карта",
		"tasks": "Задания",
		"menu": "Меню"
	},

	"avatar-info": {
		"header": {
			"title": "Аватар"
		},
		"level": "Уровень",
		"health": "Здоровье",
		"stamina": "Выносливость",
		"mana": "Мана",
		"buttons": {
			"skills": "Навыки"
		}
	},
	"avatar-skills": {
		"header": {
			"title": "Навыки персонажа"
		},
		"stamina": "Выносливость",
		"combat": "Сражение",
		"extracting": "Добыча",
		"magic": "Магия",
		"crafting": "Крафтинг",
		"cooking": "Готовка",
		"potionery": "Зельеваренье"
	},
	"map": {
		"header": {
			"title": "Карта"
		}
	},
	"location": {
		"durability": "Прочность",
		"health": "Здоровье"
	},
	"tasks": {
		"header": {
			"title": "Задания"
		},
		"socials": "Социальные",
		"guilds": "Гильдии",
		"empty": "Список пуст"
	},
	"task-info": {
		"header": {
			"social": "Социальное задание",
			"guild": "Задание гильдии"
		},
		"description-title": "Получите награду за выполнение",
		"buttons": {
			"remaining": "Осталось: {{time}}",
			"pickup-reward": "Получить награду"
		}
	},
	"crafting": {
		"header": {
			"title": "Крафтинг"
		},
		"search-placeholder": "Поиск"
	},
	"craft": {
		"header": {
			"title": "Крафт предмета"
		},
		"stats-names": {
			"description": "Описание: ",
			"rarity": "Редкость: ",
			"durability": "Прочность: ",
			"efficiency": "Эффективность: ",
			"damage": "Урон: ",
			"protection": "Защита: ",
			"capacity": "Вместимость: ",
			"saturation": "Насышение: ",
			"mana-usage": "Использование маны: ",
			"mana-multiplier": "Магический множитель: ",
			"weight": "Вес: ",
			"requirements": "Требования: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} ед.",
			"efficiency": "{{value}} ед.",
			"damage": "{{value}} ед.",
			"protection": "{{value}} ед.",
			"capacity": "{{value}} кг",
			"saturation": "{{value}} ед.",
			"mana-usage": "{{usage}} ед.",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} кг"
		},
		"rarities": {
			"common": "обычная",
			"uncommon": "необычная",
			"rare": "редкая",
			"mythical": "мифическая",
			"legendary": "легендарная",
			"ancient": "древная",
			"eternal": "вечная"
		},
		"requirements": {
			"avatar-level": "{{value}} уровень персонажа",
			"combat-level": "{{value}} уровень сражения"
		},
		"unrepairable": "Невозможна",
		"counts": {
			"1": "1 штука",
			"5": "5 штук",
			"10": "10 штук",
			"20": "20 штук",
			"50": "50 штук"
		},
		"modals": {
			"count": "Выберите количество"
		},
		"buttons": {
			"craft": "Создать",
			"count": "Количество: {{count}} штук(-а)"
		}
	},
	"avatar-equipment": {
		"header": {
			"title": "Снаряжение"
		},
		"body": "Броня",
		"leggs": "Штаны",
		"boots": "Ботинки",
		"pickaxe": "Кирка",
		"shovel": "Лопата",
		"axe": "Топор",
		"left-arm": "Левая рука",
		"right-arm": "Правая рука",
		"backpack": "Рюкзак"
	},
	"guild": {
		"header": {
			"title": "Гильдия авантюристов"
		},
		"ranks": {
			"1": "Ранг I:  Пепельник"
		},
		"tasks": "Доска заданий",
		"buttons": {
			"invite": "Пригласить авантюриста"
		}
	},
	"guild-task-info": {
		"header": {
			"title": "Задание гильдии"
		},
		"description-title": "Получите награду за выполнение",
		"buttons": {
			"take": "Взять задание"
		}
	},
	"referrals": {
		"header": {
			"title": "Реферальная система"
		},
		"you-invite": "Вы пригласили",
		"referrals-count": "{{count}} авантюристов",
		"description-title": "Получайте награду за приглашения",
		"description-text": "Приглашайте друзей в игру, чтобы получать награду за каждое их повышение в гильдии авантюристов",
		"rank": "Ранг {{level}}:",
		"reward": "+{{count}} нанит",
		"buttons": {
			"share": "Поделиться с друзьями"
		},
		"alerts": {
			"copied": "Пригласительная ссылка скопирована!"
		}
	},
	"inventory": {
		"header": {
			"title": "Инвентарь({{current}}/{{maximum}}кг)"
		}
	},
	"guesthouse": {
		"header": {
			"title": "Гостиница"
		},
		"buttons": {
			"open-chest": "Открыть сундук",
			"rest": "Отдохнуть",
			"resting": "Осталось отдохнуть: {{time}}"
		}
	},
	"townhall": {
		"header": {
			"title": "Мэрия"
		},
		"news": "Новости",
		"empty": "Список пуст"
	},
	"fundraising": {
		"header": {
			"title": "Сбор средств"
		},
		"buttons": {
			"donate": "Пожертвовать"
		}
	},
	"bank": {
		"header": {
			"title": "Банк"
		},
		"your-balance": "Ваш баланс:",
		"bank-balance": "Баланс в банке:",
		"deposits": "Вклады",
		"days": "({{count}} дней)"
	},
	"deposit": {
		"header": {
			"title": "Вклад"
		},
		"name": "Название:",
		"deadline": "Срок:",
		"days": "{{count}} дней",
		"indefinitely": "бессрочно",
		"store": "Хранится:",
		"rate": "Ставка:",
		"buttons": {
			"deposit-funds": "Внести средства",
			"Withdrawal-funds": "Вывести средства",
			"close-early": "Закрыть досрочно",
			"take-savings": "Забрать накопления"
		}
	},

	"color-picker-modal": {
		"header": {
			"title": "Выберите цвет"
		}
	},
	"main-menu-modal": {
		"header": {
			"title": "Выберите страницу"
		},
		"buttons": {
			"avatar": "Аватар",
			"map": "Карта",
			"tasks": "Задания",
			"crafting": "Крафтинг",
			"equipment": "Снаряжение",
			"guild": "Гильдия",
			"inventory": "Инвентарь",
			"guesthouse": "Гостиница",
			"townhall": "Мэрия",
			"bank": "Банк"
		}
	},
	"location-info": {
		"header": {
			"title": "Информация о локации"
		},
		"resources": "Можно добыть:",
		"enemies": "Можно встретить:",
		"buttons": {
			"hit-road": "Отправиться в путь"
		}
	},
	"location-inventory-modal": {
		"header": {
			"title-1": "Инвентарь({{current}}/{{maximum}}кг)",
			"title-2": "Описание предмета"
		},
		"stats-names": {
			"description": "Описание: ",
			"rarity": "Редкость: ",
			"durability": "Прочность: ",
			"efficiency": "Эффективность: ",
			"damage": "Урон: ",
			"protection": "Защита: ",
			"capacity": "Вместимость: ",
			"saturation": "Насышение: ",
			"mana-usage": "Использование маны: ",
			"mana-multiplier": "Магический множитель: ",
			"weight": "Вес: ",
			"requirements": "Требования: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} ед.",
			"efficiency": "{{value}} ед.",
			"damage": "{{value}} ед.",
			"protection": "{{value}} ед.",
			"capacity": "{{value}} кг",
			"saturation": "{{value}} ед.",
			"mana-usage": "{{usage}} ед.",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} кг"
		},
		"rarities": {
			"common": "обычная",
			"uncommon": "необычная",
			"rare": "редкая",
			"mythical": "мифическая",
			"legendary": "легендарная",
			"ancient": "древная",
			"eternal": "вечная"
		},
		"requirements": {
			"avatar-level": "{{value}} уровень персонажа",
			"combat-level": "{{value}} уровень сражения"
		},
		"buttons": {
			"use-item": "Использовать"
		}
	},
	"confirm-location-action-modal": {
		"header": {
			"title": "Подтверждение"
		},
		"confirm-extraction": "Вы уверены, что хотите добыть этот материал, это потратит {{cost}} единиц выносливости?",
		"confirm-attack": "Вы уверены, что хотите атаковать существо, это потратит {{cost}} единиц выносливости?",
		"buttons": {
			"extract": "Добыть",
			"attack": "Атаковать"
		}
	},
	"loot-received-modal": {
		"header": {
			"title": "Полученные предметы"
		}
	},
	"crafted-item-modal": {
		"header": {
			"title": "Скрафченный предмет"
		}
	},
	"equipment-inventory-modal": {
		"header": {
			"title-description": "Описание предмета",
			"title-body": "Выберите броню",
			"title-leggs": "Выберите штаны",
			"title-boots": "Выберите ботинки",
			"title-pickaxe": "Выберите кирку",
			"title-shovel": "Выберите лопату",
			"title-axe": "Выберите топор",
			"title-leftArm": "Выберите в левую руку",
			"title-rightArm": "Выберите в правую руку",
			"title-backpack": "Выберите рюкзак"
		},
		"stats-names": {
			"description": "Описание: ",
			"rarity": "Редкость: ",
			"durability": "Прочность: ",
			"efficiency": "Эффективность: ",
			"damage": "Урон: ",
			"protection": "Защита: ",
			"capacity": "Вместимость: ",
			"saturation": "Насышение: ",
			"mana-usage": "Использование маны: ",
			"mana-multiplier": "Магический множитель: ",
			"weight": "Вес: ",
			"requirements": "Требования: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} ед.",
			"efficiency": "{{value}} ед.",
			"damage": "{{value}} ед.",
			"protection": "{{value}} ед.",
			"capacity": "{{value}} кг",
			"saturation": "{{value}} ед.",
			"mana-usage": "{{usage}} ед.",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} кг"
		},
		"rarities": {
			"common": "обычная",
			"uncommon": "необычная",
			"rare": "редкая",
			"mythical": "мифическая",
			"legendary": "легендарная",
			"ancient": "древная",
			"eternal": "вечная"
		},
		"requirements": {
			"avatar-level": "{{value}} уровень персонажа",
			"combat-level": "{{value}} уровень сражения"
		},
		"buttons": {
			"set-equipment": "Выбрать снаряжение"
		}
	},
	"guild-tasks-limit-reached-modal": {
		"header": {
			"title": "Подтверждение"
		},
		"description": "Вы достигли лимита по количеству взятых заданий, завершите их, чтобы взять новые",
		"buttons": {
			"confirm": "Закрыть"
		}
	},
	"inventory-item-info-modal": {
		"header": {
			"title": "Информация о предмете"
		},
		"stats-names": {
			"description": "Описание: ",
			"rarity": "Редкость: ",
			"durability": "Прочность: ",
			"efficiency": "Эффективность: ",
			"damage": "Урон: ",
			"protection": "Защита: ",
			"capacity": "Вместимость: ",
			"saturation": "Насышение: ",
			"mana-usage": "Использование маны: ",
			"mana-multiplier": "Магический множитель: ",
			"weight": "Вес: ",
			"requirements": "Требования: "
		},
		"stats": {
			"rarity": "{{value}}",
			"durability": "{{current}}/{{maximum}} ед.",
			"efficiency": "{{value}} ед.",
			"damage": "{{value}} ед.",
			"protection": "{{value}} ед.",
			"capacity": "{{value}} кг",
			"saturation": "{{value}} ед.",
			"mana-usage": "{{usage}} ед.",
			"mana-multiplier": "x{{multiplier}}",
			"weight": "{{value}} кг"
		},
		"rarities": {
			"common": "обычная",
			"uncommon": "необычная",
			"rare": "редкая",
			"mythical": "мифическая",
			"legendary": "легендарная",
			"ancient": "древная",
			"eternal": "вечная"
		},
		"requirements": {
			"avatar-level": "{{value}} уровень персонажа",
			"combat-level": "{{value}} уровень сражения"
		}
	},
	"guesthouse-chest-modal": {
		"header": {
			"title": "Сундук в гостинице"
		},
		"chest": "Сундук",
		"inventory": "Инвентарь ({{current}}/{{maximum}}кг)"
	},
	"confirm-rest-modal": {
		"header": {
			"title": "Подтверждение"
		},
		"description": "Вы уверены, что хотите отдохнуть потому, что 30 минут вы будете ограничены в действиях, но полностью восстановитесь?",
		"buttons": {
			"rest": "Отдохнуть"
		}
	},
	"donate-amount-modal": {
		"header": {
			"title": "Пожертвовать средства"
		},
		"amount": "Сумма для пожертвования",
		"buttons": {
			"confirm": "Подтвердить"
		}
	},
	"confirm-level-upgrade-modal": {
		"header": {
			"title": "Подтверждение"
		},
		"description": "Вы уверены, что хотите увеличить уровень {{type}}, это будет стоить {{price}} нанитов?",
		"types": {
			"avatar": "аватара",
			"stamina": "навыка выносливости",
			"combat": "навыка сражения",
			"magic": "навыка магии",
			"extracting": "навыка добычи",
			"crafting": "навыка крафтинга"
		},
		"buttons": {
			"confirm": "Поднять уровень"
		}
	},
	"confirm-deposit-early-closing-modal": {
		"header": {
			"title": "Подтверждение"
		},
		"description": "Вы уверены, что хотите досрочно закрыть вклад, в таком случае банк удержить 10% от суммы вклада(не меньше 1 нанита)?",
		"buttons": {
			"confirm": "Закрыть досрочно"
		}
	},
	"deposit-funds-modal": {
		"header": {
			"title": "Внести средства"
		},
		"amount": "Сумма для депозита",
		"buttons": {
			"confirm": "Подтвердить депозит"
		}
	},
	"withdrawal-funds-modal": {
		"header": {
			"title": "Вывести средства"
		},
		"amount": "Сумма для вывода",
		"buttons": {
			"confirm": "Подтвердить вывод"
		}
	},

	"resources": {
		"wood": "дерево",
		"silver": "серебро"
	}
}
