import { useLocation, useNavigate } from 'react-router';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import IncompletedIcon from '../../components/Icons/IncompletedIcon';
import RightArrowIcon from '../../components/Icons/RightArrowIcon';
import DefaultButton from '../../components/Buttons/DefaultButton';
import CompletedIcon from '../../components/Icons/CompletedIcon';
import CloseIcon from '../../components/Icons/CloseIcon';

import NanitImage from '../../assets/images/nanit.png';

import { useTaskStep, checkTaskStep, completeTask } from '../../store/actions/avatar';
import { openModal } from '../../store/actions/modals';

import { socket_url } from '../../common/config';

export default connect(
	(s) => ({
		inventory: s.inventoryManager.avatar,
		guildTasks: s.avatarManager.tasks.guild,
		socialTasks: s.avatarManager.tasks.social,
	}),
	{
		openModal,
		useTaskStep,
		completeTask,
		checkTaskStep,
	}
)(({ inventory, guildTasks, socialTasks, openModal, useTaskStep, completeTask, checkTaskStep }) => {
	const { lastMessage } = useWebSocket(
		socket_url,
		{
			shouldReconnect: () => true,
			reconnectAttempts: 1000,
			reconnectInterval: 200,
			share: true,
		},
		true
	);

	const [IsComplete, SetIsComplete] = React.useState(false);
	const [EndsAt, SetEndsAt] = React.useState('00:00');
	const EndsAtInterval = React.useRef();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const {
		state: { task, taskType },
	} = useLocation();
	const [Task, SetTask] = React.useState(task);

	const updateEndsAt = () => {
		const timeDifferenceInMinutes = (new Date(Task.endsAt).getTime() - new Date().getTime()) / 1000 / 60;

		let hours = Math.floor(timeDifferenceInMinutes / 60);

		if (hours < 10) hours = `0${hours}`;

		let minutes = Math.floor(timeDifferenceInMinutes % 60);

		if (minutes < 10) minutes = `0${minutes}`;

		SetEndsAt(`${hours}:${minutes}`);
	};

	const useTaskStepWrapper = (taskId, step) => {
		useTaskStep({ taskId, stepIndex: step.index });

		window.open(step.href, '_blank');
	};

	React.useEffect(() => {
		SetIsComplete(
			Task.steps.filter(
				(v) => v.completed || (v.type == 'collect' && inventory.items.filter((iv) => iv.item == v.item).length >= v.count)
			).length == Task.steps.length
		);

		updateEndsAt();

		EndsAtInterval.current = setInterval(() => {
			updateEndsAt();
		}, 60 * 1000);

		return () => {
			clearInterval(EndsAtInterval.current);
		};
	}, [Task]);

	React.useEffect(() => {
		let task = socialTasks.find((v) => v._id == Task._id);

		if (task) {
			SetTask(task);

			return;
		}

		task = guildTasks.find((v) => v._id == Task._id);

		if (task) {
			SetTask(task);

			return;
		}
	}, [socialTasks, guildTasks]);

	React.useEffect(() => {
		if (lastMessage) {
			try {
				let content = JSON.parse(lastMessage.data);

				switch (content.type) {
					case 'tasks-loot-received': {
						if (content.response.success) {
							openModal({
								name: 'loot-received',
								payload: {
									type: 'custom',
									withoutPadding: true,
									items: content.response.result.items.map((v) => ({
										...(v.type == 'currency' ? { item: { image: NanitImage } } : { item: v.item }),
										count: v.count,
									})),
									onClose: () => {
										navigate(-1);
									},
								},
							});
						} else {
							toast.error(content.response.message[i18n.languages[0]], {
								position: 'top-center',
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								autoClose: 5000,
								draggable: true,
							});
						}

						break;
					}
				}
			} catch (error) {}
		}
	}, [lastMessage]);

	return (
		<div className={classNames({ container: true, 'task-info': true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`task-info.header.${taskType}`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div
					style={{ backgroundImage: `url(${Task.cover})` }}
					className={classNames({ cover: true })}
				></div>
				<div className={classNames({ info: true })}>
					<span className={classNames({ 'task-name': true })}>{Task.name[i18n.languages[0]]}</span>
					<div className={classNames({ steps: true })}>
						<div className={classNames({ 'task-description': true })}>
							<img
								className={classNames({ 'task-description-image': true })}
								src={Task.image}
							/>
							<div className={classNames({ 'task-description-info': true })}>
								<span className={classNames({ 'task-description-info-title': true })}>{t('task-info.description-title')}</span>
								<span className={classNames({ 'task-description-info-text': true })}>{Task.description[i18n.languages[0]]}</span>
								<div className={classNames({ 'task-description-reward': true })}>
									{Task.reward.map((v, i) => (
										<div
											className={classNames({ item: true })}
											key={i}
										>
											<img
												className={classNames({ 'item-image': true })}
												src={v.type == 'currency' ? NanitImage : v.item.image}
											/>
											<span className={classNames({ 'item-count': true })}>x{v.count}</span>
										</div>
									))}
								</div>
							</div>
						</div>
						{Task.steps.map((v, i) => (
							<div
								onClick={() =>
									v.href && !v.used
										? useTaskStepWrapper(Task._id, { index: i, href: v.href })
										: v.href && v.used && v.confirm == 'request' && new Date(v.delayTo) <= new Date()
										? checkTaskStep({ taskId: Task._id, stepIndex: i })
										: null
								}
								className={classNames({ step: true })}
								key={i}
							>
								<img
									className={classNames({ 'step-image': true })}
									src={v.image}
								/>
								<span className={classNames({ 'step-description': true })}>{v.description[i18n.languages[0]]}</span>
								{v.completed || (v.type == 'collect' && inventory.items.filter((iv) => iv.item == v.item).length >= v.count) ? (
									<CompletedIcon
										width={24}
										height={24}
										fill={'#009933'}
									/>
								) : !v.completed && v.used && v.confirm == 'request' && new Date(v.delayTo) <= new Date() ? (
									<CompletedIcon
										width={24}
										height={24}
										fill={'#FF9900'}
									/>
								) : !v.completed && v.used ? (
									<span className={classNames({ 'circle-loader': true })} />
								) : v.href ? (
									<RightArrowIcon
										width={15}
										height={13}
										stroke='#FFFFFF'
									/>
								) : v.type == 'kill' || v.type == 'extract' ? (
									<span className={classNames({ 'step-count': true })}>
										{v.current}/{v.count}
									</span>
								) : v.type == 'collect' ? (
									<span className={classNames({ 'step-count': true })}>
										{inventory.items.filter((iv) => iv.item == v.item).length}/{v.count}
									</span>
								) : (
									<IncompletedIcon
										width={24}
										height={24}
										fill={'#FF3300'}
									/>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={classNames({ 'action-button': true })}>
				<DefaultButton
					text={IsComplete ? t('task-info.buttons.pickup-reward') : t('task-info.buttons.remaining', { time: EndsAt })}
					onClick={() => completeTask({ taskId: Task._id, taskType })}
					disabled={!IsComplete}
					type={'primary'}
					fontSize={20}
					height={50}
				/>
			</div>
		</div>
	);
});
