import './style.sass';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import CloseIcon from '../../Icons/CloseIcon';

import { itemStats } from '../../../common/items';

export default ({ item, closeModal }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={classNames({ modal: true, 'inventory-item-info': true })}>
			<div className={classNames({ 'modal-header': true })}>
				<div
					className={classNames({ 'modal-header-close': true })}
					onClick={closeModal}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'modal-header-title': true })}>{t('inventory-item-info-modal.header.title')}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'item-info': true })}>
					<img
						className={classNames({ 'item-image': true })}
						src={item.image}
					/>
					<div className={classNames({ 'item-stats': true })}>
						<span className={classNames({ 'item-stats-text': true, strong: true, center: true })}>
							{item.name[i18n.languages[0]]}
						</span>
						<span className={classNames({ 'item-stats-text': true })}>
							<span className={classNames({ 'item-stats-text': true, strong: true })}>
								{t(`inventory-item-info-modal.stats-names.description`)}
							</span>
							{item.description[i18n.languages[0]]}
						</span>
						{itemStats[item.type].map((v, i) => (
							<span
								className={classNames({ 'item-stats-text': true })}
								key={i}
							>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`inventory-item-info-modal.stats-names.${v}`)}
								</span>
								{t(
									`inventory-item-info-modal.stats.${v}`,
									typeof item[v.split('-')[0]] == 'object'
										? { ...item[v.split('-')[0]] }
										: { value: v == 'rarity' ? t(`location-inventory-modal.rarities.${item[v]}`) : item[v] }
								)}
							</span>
						))}
						{item.requires.length ? (
							<span className={classNames({ 'item-stats-text': true })}>
								<span className={classNames({ 'item-stats-text': true, strong: true })}>
									{t(`inventory-item-info-modal.stats-names.requirements`)}
								</span>
								{item.requires.map((v) => t(`inventory-item-info-modal.requirements.${v.name}`, { value: v.value })).join(', ')}
							</span>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
