import { createActionFactory } from '../../../utils/store/helpers';

const factory = createActionFactory('GUILD');

export const getGuildTasks = factory.create('GET_GUILD_TASKS');
export const getGuildTasksAsync = factory.createAsync('GET_GUILD_TASKS_ASYNC');

export const getReferralsCount = factory.create('GET_REFERRALS_COUNT');
export const getReferralsCountAsync = factory.createAsync('GET_REFERRALS_COUNT_ASYNC');

export const takeGuildTask = factory.create('TAKE_GUILD_TASK');
export const takeGuildTaskAsync = factory.createAsync('TAKE_GUILD_TASK_ASYNC');

export const clearGuildError = factory.create('CLEAR_GUILD_ERROR');
export const clearGuildErrorAsync = factory.createAsync('CLEAR_GUILD_ERROR_ASYNC');
