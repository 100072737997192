import { useNavigate, Navigate } from 'react-router';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultProgressBar from '../../components/ProgressBars/DefaultProgressBar';
import CircleProgressBar from '../../components/ProgressBars/CircleProgressBar';
import FireProtectionIcon from '../../components/Icons/FireProtectionIcon';
import RegenerationIcon from '../../components/Icons/RegenerationIcon';
import InventoryIcon from '../../components/Icons/InventoryIcon';
import StrengthIcon from '../../components/Icons/StrengthIcon';
import PickaxeIcon from '../../components/Icons/PickaxeIcon';
import ForwardIcon from '../../components/Icons/ForwardIcon';
import PoisonIcon from '../../components/Icons/PoisonIcon';
import SwordsIcon from '../../components/Icons/SwordsIcon';
import ReturnIcon from '../../components/Icons/ReturnIcon';
import LuckyIcon from '../../components/Icons/LuckyIcon';
import MainHeader from '../../components/MainHeader';

import { updateLocationTarget, clearLocation } from '../../store/actions/locations';
import { getAvatarEffects } from '../../store/actions/avatar';
import { openModal } from '../../store/actions/modals';

import { socket_url } from '../../common/config';

export default connect(
	(s) => ({
		effects: s.avatarManager.effects,
		mana: s.avatarManager.state.mana,
		validated: s.userManager.validated,
		location: s.locationsManager.current,
		health: s.avatarManager.state.health,
		inventory: s.inventoryManager.avatar,
		stamina: s.avatarManager.state.stamina,
	}),
	{
		openModal,
		clearLocation,
		getAvatarEffects,
		updateLocationTarget,
	}
)(
	({
		mana,
		health,
		stamina,
		effects,
		location,
		inventory,
		validated,

		openModal,
		clearLocation,
		getAvatarEffects,
		updateLocationTarget,
	}) => {
		const { lastMessage, sendMessage } = useWebSocket(
			socket_url,
			{
				share: true,
				shouldReconnect: () => true,
				reconnectAttempts: 1000,
				reconnectInterval: 200,
			},
			true
		);
		const [Dimensions, SetDimensions] = React.useState({ width: window.innerWidth, height: window.innerHeight });
		const [TargetLoading, SetTargetLoading] = React.useState(true);
		const [ShowEffect, SetShowEffect] = React.useState('');
		const loaderWrapper = React.useRef(false);
		const { t, i18n } = useTranslation();
		const navigate = useNavigate();

		const locationReturn = () => {
			sendMessage(JSON.stringify({ type: 'location-return' }));
		};

		const locationActionConfirm = () => {
			sendMessage(JSON.stringify({ type: 'location-action-confirm' }));
		};

		const locationForward = () => {
			sendMessage(JSON.stringify({ type: 'location-forward' }));

			SetTargetLoading(true);
		};

		const locationTargetClick = () => {
			sendMessage(JSON.stringify({ type: 'location-target-click' }));
		};

		React.useEffect(() => {
			const handleResize = () => {
				SetDimensions({ width: window.innerWidth, height: window.innerHeight });
			};

			window.addEventListener('resize', handleResize);

			return () => window.removeEventListener('resize', handleResize);
		}, []);

		React.useEffect(() => {
			if (validated) {
				getAvatarEffects();
			}
		}, [validated]);

		React.useEffect(() => {
			if (
				location &&
				((location.target.type == 'resource' && location.target.durability.current != 0) ||
					(location.target.type == 'mob' && location.target.health.current != 0))
			)
				SetTargetLoading(false);
		}, [location]);

		React.useEffect(() => {
			if (lastMessage) {
				try {
					let content = JSON.parse(lastMessage.data);

					switch (content.type) {
						case 'location-target-update': {
							updateLocationTarget(content.response);

							break;
						}
						case 'location-clear': {
							navigate('/map');

							clearLocation();

							break;
						}
						case 'location-loot-received': {
							if (content.response.success) {
								openModal({
									name: 'loot-received',
									payload: {
										type: 'custom',
										withoutPadding: true,
										items: content.response.result.items,
										onClose: () => {},
									},
								});
							} else {
								toast.error(content.response.message[i18n.languages[0]], {
									position: 'top-center',
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									autoClose: 5000,
									draggable: true,
								});
							}

							break;
						}
					}
				} catch (error) {}
			}
		}, [lastMessage]);

		if (!location) return <Navigate to={'/avatar-info'} />;

		return (
			<div className={classNames({ container: true, location: true })}>
				<MainHeader title={location.name[i18n.languages[0]]} />
				<div
					className={classNames({ content: true })}
					style={{ backgroundImage: `url(${location.image})` }}
				>
					<div className={classNames({ 'health-or-durability-wrapper': true, hidden: TargetLoading })}>
						{location.target.type == 'resource' ? (
							<DefaultProgressBar
								current={location.target.durability.current}
								maximum={location.target.durability.maximum}
								name={t('location.durability')}
								frontColor={'#CC690E'}
								backColor={'#7C3F07'}
								level={12}
							/>
						) : (
							<DefaultProgressBar
								current={location.target.health.current}
								maximum={location.target.health.maximum}
								name={t('location.health')}
								frontColor={'#009933'}
								backColor={'#00551C'}
								level={12}
							/>
						)}
					</div>
					<div
						className={classNames({ 'target-image-wrapper': true })}
						onGestureStart={(e) => e.preventDefault()}
					>
						{TargetLoading ? (
							<div
								className={classNames({ 'loader-wrapper': true })}
								ref={(ref) => (loaderWrapper.current = ref)}
							>
								<span className={classNames({ loader: true })} />
							</div>
						) : (
							<img
								onTouchEnd={(e) => (e.touches.length === 0 && location.target.actionConfirmed ? locationTargetClick() : null)}
								src={location.target.type == 'resource' ? location.target.resource.image : location.target.mob.image}
								onClick={() => (location.target.actionConfirmed ? locationTargetClick() : null)}
								className={classNames({ 'target-image': true })}
							/>
						)}
					</div>
					<div className={classNames({ 'effects-wrapper': true })}>
						<div className={classNames({ effects: true })}>
							{effects.map((v, i) => (
								<div
									className={classNames({ effect: true })}
									onClick={() => SetShowEffect(ShowEffect == v.type ? '' : v.type)}
									key={i}
								>
									<div className={classNames({ 'effect-description': true, hidden: ShowEffect !== v.type })}>
										{v.icon}
										<div className={classNames({ 'effect-description-info': true })}>
											<span className={classNames({ 'effect-description-name': true })}>{v.name[i18n.languages[0]]}</span>
											<span className={classNames({ 'effect-description-time': true })}>{v.time}</span>
										</div>
									</div>
									{v.icon}
									<span className={classNames({ 'effect-time': true })}>{v.time}</span>
								</div>
							))}
						</div>
					</div>
					<div className={classNames({ stats: true })}>
						<div className={classNames({ 'stats-wrapper': true })}>
							<CircleProgressBar
								frontWidth={Dimensions.height > 590 ? 7 : 5}
								backWidth={Dimensions.height > 590 ? 7 : 5}
								size={Dimensions.height > 590 ? 50 : 40}
								current={health.current}
								maximum={health.maximum}
								frontColor={'#009933'}
								backColor={'#00551C'}
							/>
						</div>
						<div className={classNames({ 'stats-wrapper': true })}>
							<CircleProgressBar
								frontWidth={Dimensions.height > 590 ? 7 : 5}
								backWidth={Dimensions.height > 590 ? 7 : 5}
								size={Dimensions.height > 590 ? 50 : 40}
								current={stamina.current}
								maximum={stamina.maximum}
								frontColor={'#3399FF'}
								backColor={'#0058AF'}
							/>
						</div>
						<div className={classNames({ 'stats-wrapper': true })}>
							<CircleProgressBar
								frontWidth={Dimensions.height > 590 ? 7 : 5}
								backWidth={Dimensions.height > 590 ? 7 : 5}
								size={Dimensions.height > 590 ? 50 : 40}
								current={mana.current}
								maximum={mana.maximum}
								frontColor={'#FF0066'}
								backColor={'#8D0039'}
							/>
						</div>
					</div>
					<div className={classNames({ actions: true })}>
						<div
							className={classNames({ 'action-wrapper': true })}
							onClick={locationReturn}
						>
							<ReturnIcon
								width={Dimensions.height > 590 ? 40 : 30}
								height={Dimensions.height > 590 ? 40 : 30}
								fill={'#ffffff'}
							/>
						</div>
						<div
							onClick={() => openModal({ name: 'location-inventory', payload: { type: 'full', inventory } })}
							className={classNames({ 'action-wrapper': true })}
						>
							<InventoryIcon
								width={Dimensions.height > 590 ? 40 : 30}
								height={Dimensions.height > 590 ? 40 : 30}
								fill={'#ffffff'}
							/>
						</div>
						<div
							className={classNames({ 'action-wrapper': true, disabled: location.target.actionConfirmed })}
							onClick={() =>
								!location.target.actionConfirmed
									? openModal({
											name: 'confirm-location-action',
											payload: {
												type: 'center',
												targetType: location.target.type,
												staminaCost:
													location.target.type == 'resource'
														? location.target.resource.staminaCost
														: location.target.mob.staminaCost,
												onConfirm: () => {
													locationActionConfirm();
												},
											},
									  })
									: null
							}
						>
							{location.target.type == 'resource' ? (
								<PickaxeIcon
									width={Dimensions.height > 590 ? 40 : 30}
									height={Dimensions.height > 590 ? 40 : 30}
									fill={location.target.actionConfirmed ? '#8D8C92' : '#ffffff'}
								/>
							) : (
								<SwordsIcon
									width={Dimensions.height > 590 ? 40 : 30}
									height={Dimensions.height > 590 ? 40 : 30}
									fill={location.target.actionConfirmed ? '#8D8C92' : '#ffffff'}
								/>
							)}
						</div>
						<div
							className={classNames({ 'action-wrapper': true, disabled: location.target.actionConfirmed })}
							onClick={() => (!location.target.actionConfirmed ? locationForward() : null)}
						>
							<ForwardIcon
								width={Dimensions.height > 590 ? 40 : 30}
								height={Dimensions.height > 590 ? 40 : 30}
								fill={location.target.actionConfirmed ? '#8D8C92' : '#ffffff'}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
);
