import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';

import DefaultButton from '../../components/Buttons/DefaultButton';
import CloseIcon from '../../components/Icons/CloseIcon';

import BankDoorImage from '../../assets/images/bank_door.png';

import { bankDepositFunds, bankWithdrawFunds } from '../../store/actions/bank';
import { openModal, closeModal } from '../../store/actions/modals';

export default connect(
	(s) => ({
		modalName: s.modals.name,
		avatarDeposits: s.bankManager.avatar,
	}),
	{
		openModal,
		closeModal,
		bankDepositFunds,
		bankWithdrawFunds,
	}
)(({ modalName, avatarDeposits, openModal, closeModal, bankDepositFunds, bankWithdrawFunds }) => {
	const {
		state: { deposit: bankDeposit },
	} = useLocation();
	const [Deposit, SetDeposit] = React.useState(
		avatarDeposits.find((v) => v.deposit == bankDeposit._id) || { ...bankDeposit, store: 0 }
	);
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (modalName) {
			const avatarDeposit = avatarDeposits.find((v) => v.deposit == bankDeposit._id);

			if (avatarDeposit) {
				SetDeposit(avatarDeposit);
			} else {
				navigate(-1);
			}

			closeModal();
		}
	}, [avatarDeposits]);

	return (
		<div className={classNames({ container: true, deposit: true })}>
			<div className={classNames({ 'task-info-header': true })}>
				<div
					className={classNames({ 'task-info-header-close': true })}
					onClick={() => navigate(-1)}
				>
					<CloseIcon
						width={16}
						height={16}
						fill={'#ffffff'}
					/>
				</div>
				<span className={classNames({ 'task-info-header-title': true })}>{t(`deposit.header.title`)}</span>
			</div>
			<div className={classNames({ content: true })}>
				<div className={classNames({ 'deposit-image-wrapper': true })}>
					<img
						className={classNames({ 'deposit-image': true })}
						src={BankDoorImage}
					/>
				</div>
				<span className={classNames({ 'deposit-text': true, strong: true })}>
					{t('deposit.name')} <span className={classNames({ 'deposit-text': true })}>{bankDeposit.name[i18n.languages[0]]}</span>
				</span>
				<span className={classNames({ 'deposit-text': true, strong: true })}>
					{t('deposit.deadline')}{' '}
					<span className={classNames({ 'deposit-text': true })}>
						{bankDeposit.deadline ? t('deposit.days', { count: bankDeposit.deadline }) : t('deposit.indefinitely')}
					</span>
				</span>
				<span className={classNames({ 'deposit-text': true, strong: true })}>
					{t('deposit.store')} <span className={classNames({ 'deposit-text': true })}>{Deposit.store} n</span>
				</span>
				{bankDeposit.rate && (
					<span className={classNames({ 'deposit-text': true, strong: true })}>
						{t('deposit.rate')} <span className={classNames({ 'deposit-text': true })}>{bankDeposit.rate}%</span>
					</span>
				)}
			</div>
			<div className={classNames({ 'action-buttons': true })}>
				{bankDeposit.deadline == 0 || Deposit.endsAt == null ? (
					<DefaultButton
						onClick={() =>
							openModal({
								name: 'deposit-funds',
								payload: {
									type: 'custom',
									withoutPadding: true,
									onConfirm: (amount) => bankDepositFunds({ depositId: bankDeposit._id, amount }),
								},
							})
						}
						text={t('deposit.buttons.deposit-funds')}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				) : null}

				{(bankDeposit.deadline == 0 || Deposit.endsAt == null) && Deposit.store > 0 ? (
					<DefaultButton
						onClick={() =>
							openModal({
								name: 'withdrawal-funds',
								payload: {
									type: 'custom',
									withoutPadding: true,
									onConfirm: (amount) => bankWithdrawFunds({ depositId: Deposit._id, amount }),
								},
							})
						}
						text={t('deposit.buttons.Withdrawal-funds')}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				) : null}

				{new Date(Deposit.endsAt) > new Date() && Deposit.store > 0 ? (
					<DefaultButton
						onClick={() =>
							openModal({
								name: 'confirm-deposit-early-closing',
								payload: { type: 'center', onConfirm: () => bankWithdrawFunds({ depositId: Deposit._id }) },
							})
						}
						text={t('deposit.buttons.close-early')}
						type={'secondary-danger'}
						fontSize={20}
						height={50}
					/>
				) : null}

				{bankDeposit.deadline != 0 && new Date(Deposit.endsAt) < new Date() && Deposit.store > 0 ? (
					<DefaultButton
						onClick={() => bankWithdrawFunds({ depositId: Deposit._id })}
						text={t('deposit.buttons.take-savings')}
						type={'primary'}
						fontSize={20}
						height={50}
					/>
				) : null}
			</div>
		</div>
	);
});
