import './style.sass';

import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import GuestHouseIcon from '../../Icons/GuestHouseIcon';
import EquipmentIcon from '../../Icons/EquipmentIcon';
import InventoryIcon from '../../Icons/InventoryIcon';
import TownHallIcon from '../../Icons/TownHallIcon';
import AvatarIcon from '../../Icons/AvatarIcon';
import CloseIcon from '../../Icons/CloseIcon';
import TasksIcon from '../../Icons/TasksIcon';
import CraftIcon from '../../Icons/CraftIcon';
import GuildIcon from '../../Icons/GuildIcon';
import BankIcon from '../../Icons/BankIcon';
import MapIcon from '../../Icons/MapIcon';

export default ({ closeModal }) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const navigateWrapper = (location) => {
		closeModal();

		navigate(location);
	};

	return (
		<div
			onClick={(e) => (e.target.classList.contains('main-menu-wrapper') ? closeModal() : null)}
			className={classNames({ modal: true, 'main-menu-wrapper': true })}
		>
			<div className={classNames({ modal: true, 'main-menu': true })}>
				<div className={classNames({ header: true })}>
					<span className={classNames({ 'header-title': true })}>{t('main-menu-modal.header.title')}</span>
					<div
						className={classNames({ 'header-close': true })}
						onClick={closeModal}
					>
						<CloseIcon
							width={16}
							height={16}
							fill={'#ffffff'}
						/>
					</div>
				</div>
				<div className={classNames({ content: true })}>
					<div className={classNames({ pages: true })}>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/avatar-info')}
						>
							<AvatarIcon
								fill={pathname == '/avatar-info' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/avatar-info' })}>
								{t('main-menu-modal.buttons.avatar')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/map')}
						>
							<MapIcon
								fill={pathname == '/map' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/map' })}>
								{t('main-menu-modal.buttons.map')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/tasks')}
						>
							<TasksIcon
								fill={pathname == '/tasks' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/tasks' })}>
								{t('main-menu-modal.buttons.tasks')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/crafting')}
						>
							<CraftIcon
								fill={pathname == '/crafting' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/crafting' })}>
								{t('main-menu-modal.buttons.crafting')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/equipment')}
						>
							<EquipmentIcon
								fill={pathname == '/equipment' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/equipment' })}>
								{t('main-menu-modal.buttons.equipment')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/guild')}
						>
							<GuildIcon
								fill={pathname == '/guild' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/guild' })}>
								{t('main-menu-modal.buttons.guild')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/inventory')}
						>
							<InventoryIcon
								fill={pathname == '/inventory' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/inventory' })}>
								{t('main-menu-modal.buttons.inventory')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/guesthouse')}
						>
							<GuestHouseIcon
								fill={pathname == '/guesthouse' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/guesthouse' })}>
								{t('main-menu-modal.buttons.guesthouse')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/townhall')}
						>
							<TownHallIcon
								fill={pathname == '/townhall' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/townhall' })}>
								{t('main-menu-modal.buttons.townhall')}
							</span>
						</div>
						<div
							className={classNames({ 'page-wrapper': true })}
							onClick={() => navigateWrapper('/bank')}
						>
							<BankIcon
								fill={pathname == '/bank' ? '#AF8EF9' : '#88878D'}
								height={30}
								width={30}
							/>
							<span className={classNames({ 'page-name': true, matched: pathname == '/bank' })}>
								{t('main-menu-modal.buttons.bank')}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
