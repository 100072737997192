import { handleActions } from 'redux-actions';

import { getGuildTasksAsync, getReferralsCountAsync, takeGuildTaskAsync, clearGuildErrorAsync } from '../../actions/guild';

import { defaultError } from '../../../common/errors';

const initialState = {
	referrals: {
		count: 0,
	},
	tasks: [],
	error: null,
};

export default handleActions(
	{
		[getGuildTasksAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			tasks: responseData.success ? responseData.result.tasks : [],
		}),
		[getReferralsCountAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			referrals: { ...s.referrals, count: responseData.success ? responseData.result.referrals : 0 },
		}),
		[takeGuildTaskAsync.success]: (s, { payload: { data: responseData } } = {}) => ({
			...s,
			error: responseData.success ? null : responseData.message ? responseData.message : defaultError,
			tasks: responseData.success ? s.tasks.filter((v) => v._id != responseData.guildTaskId) : [],
		}),
		[clearGuildErrorAsync.success]: (s, p) => ({ ...s, error: null }),

		[getGuildTasksAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
			tasks: [],
		}),
		[takeGuildTaskAsync.failed]: (s, { payload: { response } } = {}) => ({
			...s,
			error:
				response && (response.statusText == 'unauthorized' || response.status == '401')
					? null
					: response && response.data.message
					? response.data.message
					: defaultError,
		}),
	},
	initialState
);
